<template>
  <div class="wrapper">
    <div class="content">
      <div class="live-broadcast-content-box">
        <div class="live-broadcast-content">
          <div class="consulting-service">
            <h1 style="height: 35px">
              <a-carousel :dot-position="'left'"  autoplay :dots='false'>
                <div v-for="(item, index) in noticeContentData" :key="index">
                  <SoundOutlined /><span @click="isAnnouncement(item)">{{ item.noticeContent }}</span>
                </div>
              </a-carousel>
            </h1>
          </div>
          <div class="live-player">
            <div class="live-player-left">
              <flv-player :configuration="configuration" v-if="configuration.url" style="width:100%;height:100%"></flv-player>
              <span @click="getInto" v-if="getFnCodeList && getFnCodeList.includes('LIVE_LOOK')">进入直播间</span>
            </div>
            <ul class="live-player-right">
              <li v-for="(item, index) in liveList" :key="index">
                <div :class="{ active: index == switchLiveType }" @click="switchLive(index, item)">
                  <img :src="item.cover" alt="" />
                  <p>
                    <span>{{ item.roomTitle }}</span>
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="list-player-box">
        <div class="list-player">
          <div class="list-player-title">
            <h3
              v-for="(item, index) in followLatelyTitle"
              :class="{ 'active-title': index == followLatelyType }"
              @click="followLatelyList(index)"
              :key="index"
            >
              {{ item.name }}
            </h3>
            <div class="qrcode">
              <div>
                <p>扫描下载安卓</p>
                <p>请浏览器下载</p>
                <qrcode-vue :value="'http://cgapk.jiketravel.com/app-logo.apk'"  style="margin-bottom: 20px;" :size="100"  level="H" />
              </div>
              <div>
                <p>扫描下载IOS</p>
                <qrcode-vue :value="'https://www.pgyer.com/3wG9'" :size="100"  level="H" />
              </div>
            </div>
          </div>
          <div class="list-wrapper-box">
            <watch-item
              :list="list"
              :isShow="!Boolean(followLatelyType)"
              v-if="list.length > 0"
              @toLive="toLive"
              @cancelFollow="cancelFollow"
            />
            <a-empty v-else>
              <template #description>
                <p>暂无直播</p>
              </template>
            </a-empty>
            <!-- <div class="more" v-if="Boolean(followLatelyType) && list.length > 0" @click="more">更多</div> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 强制关闭 -->
    <revision-modal :title="modal.title" :class="[!modal.noticeTitle ? 'password-box' : '']">
      <template v-slot:content>
        <div v-show="modal.noticeTitle" v-html="modal.content"></div>
        <a-input v-show="!modal.noticeTitle" v-model:value="passwordCheckValue.password" />
      </template>
      <template v-slot:footer>
        <a-button type="primary" v-show="!modal.noticeTitle" @click="handleCancel" class="button-modal" ghost
          >取消</a-button
        >
        <a-button type="primary" @click="ok" class="button-modal">确认</a-button>
      </template>
    </revision-modal>
  </div>
</template>

<script>
// import HlsPlayer from '@/components/HlsPlayer.vue';
// import CkPlayer from '@/components/CkPlayer.vue';
import FlvPlayer from '@/components/FlvPlayer.vue';
import { SoundOutlined } from '@ant-design/icons-vue';
import WatchItem from '@/components/WatchItem.vue';
import { ref, onMounted, provide, computed } from 'vue';
import api from '@/services';
import QrcodeVue from 'qrcode.vue';
import { message } from 'ant-design-vue';
import RevisionModal from '@/components/RevisionModal.vue';
import { useRouter } from 'vue-router';
import { fieldName, video_attribute, live_attribute, typeText } from '@/store/fieldConfiguration';
export default {
  components: {
    SoundOutlined,
    WatchItem,
    RevisionModal,
    FlvPlayer,
    QrcodeVue
    // CkPlayer,
    // HlsPlayer
  },
  setup() {
    const getFnCodeList = computed(() => {
      return JSON.parse(localStorage.getItem('fnCodeList'));
    });
    const router = useRouter();
    // 直播配置
    const configuration = ref({
      // id: 'container',
      // autoplay: false,
      // live: true,
      url: ''
    });

    // 推送切换
    let switchLiveType = ref(0);
    const id = ref('');
    const roomUserId = ref('');
    const liveType = ref('');
    const switchLive = (index, item) => {
      switchLiveType.value = index;
      configuration.value.url = item.videoResourcesVO.pullAddressFlv;
      id.value = item.id;
      roomUserId.value = item.roomUserId;
    };

    // 判断是我的关注  还是最近观看
    let followLatelyType = ref(0);
    const followLatelyList = params => {
      console.log(params);
      list.value = [];
      if (!params) {
        getMyAttentionList();
      } else {
        getRecentlyWatched_List();
      }
      followLatelyType.value = params;
    };

    // 右侧列表 默认使用直播列表的接口
    const liveList = ref([]);
    const liveListParams = {
      curPage: 1,
      pageSize: 5
    };
    const getRecommender = async () => {
      const { data } = await api.getRecommender(liveListParams);
      liveList.value = data?.records || [];
      if (data?.records && configuration.value.url.length <= 0) {
        // 1 无人机直播  2 资源直播 3 普通直播
        // configuration.value.url = data?.records[0].videoResourcesVO.pullAddressHls;
        configuration.value.url = data?.records[0].videoResourcesVO.pullAddressFlv;
        id.value = data?.records[0].id;
        roomUserId.value = data?.records[0].roomUserId;
        liveType.value = data?.records[0].liveType;
      }
    };

    const list = ref([]);
    // 获取我的关注
    const myAttentionParams = ref({
      curPage: 1,
      pageSize: 10,
      status: 1
    });
    const getMyAttentionList = async () => {
      const { data } = await api.getMyAttentionList(myAttentionParams.value);
      list.value =
        data?.records?.map(item => {
          item.type = typeText[item.type];
          let paramsJson = JSON.stringify(item);
          live_attribute.forEach((oldKey, index) => {
            const reg = oldKey;
            paramsJson = paramsJson.replace(reg, fieldName[index]);
          });
          return JSON.parse(paramsJson);
        }) || [];
    };

    // 最近观看
    const RecentlyWatchedParams = ref({
      curPage: 1,
      pageSize: 10
    });
    const getRecentlyWatched_List = async () => {
      try {
        const { data } = await api.getRecentlyWatchedList(RecentlyWatchedParams.value);
        list.value = data?.records.map(item => {
          item.lastOnlineTime = `浏览时间 ${item.lastOnlineTime}`;
          item.type = typeText[item.type];
          let paramsJson = JSON.stringify(item);
          video_attribute.forEach((oldKey, index) => {
            const reg = oldKey;
            paramsJson = paramsJson.replace(reg, fieldName[index]);
          });
          return JSON.parse(paramsJson);
        });
      } catch (error) {
        console.error(error);
      }
    };

    // 更多 按钮
    const more = () => {
      if (followLatelyType.value) {
        router.push({
          path: '/video-recording'
        });
      } else {
        myAttentionParams.value.pageSize += 10;
        getMyAttentionList();
      }
    };

    // 进入直播间按钮
    const getInto = () => {
      if (configuration.value.url) {
        router.push({
          path: '/live-broadcast-watch',
          query: {
            pullFlow: configuration.value.url,
            id: id.value,
            roomUserId: roomUserId.value,
            liveType: liveType.value
          }
        });
      }
    };

    // 最上方消息获取
    const modal = ref({
      title: '',
      content: ''
    });

    const noticeContentData = ref([]);
    const getMessage = async () => {
      const { data } = await api.getMessagePush();
      noticeContentData.value = data;
      
    };

    // 点击公告显示
    const visible = ref(false);
    provide('visible', visible);
    const isAnnouncement = (item) => {
      visible.value = true;
      modal.value = {
        title: item,
        content: item.noticeContent,
        noticeTitle: true
      };
    };

    const ok = async () => {
      if (modal.value.noticeTitle) {
        handleCancel();
      } else {
        const { data } = await api.passwordCheck(passwordCheckValue.value);
        if (data) {
          router.push({
            path: '/live-broadcast-watch',
            query: { ...query.value }
          });
        } else {
          message.error('密码错误');
        }
      }
    };

    const handleCancel = () => {
      visible.value = false;
    };

    // 取消关注
    const cancelFollow = async item => {
      const { data } = await api.cancelFollow(item.roomUserId);
      getMyAttentionList();
    };

    // 进直播间
    provide('visible', visible);
    const passwordCheckValue = ref({
      password: '',
      roomId: ''
    });
    const query = ref({
      pullFlow: '',
      id: '',
      roomUserId: '',
      liveType: ''
    });
    const toLive = data => {
      modal.value = {
        title: '即将进入加密直播间，请输入直播间密码',
        noticeTitle: false
      };
      // query.value.pullFlow = data.videoResourcesVO.pullAddressHls;
      query.value.pullFlow = data.videoResourcesVO.pullAddressFlv;
      query.value.id = data.id;
      query.value.roomUserId = data.roomUserId;
      query.value.liveType = data.liveType;
      if (!data.password) {
        router.push({
          path: '/live-broadcast-watch',
          query: { ...query.value }
        });
        return false;
      }
      visible.value = true;
      passwordCheckValue.value.roomId = data.id;
    };

    onMounted(() => {
      getMessage();
      getMyAttentionList();
      getRecommender();
    });

    return {
      getFnCodeList,
      liveList,

      noticeContentData,

      list,
      more,
      configuration,
      getInto,
      isAnnouncement,
      visible,
      switchLive,
      switchLiveType,
      followLatelyType,
      followLatelyList,
      followLatelyTitle: [
        {
          name: '我的关注'
        },
        {
          name: '最近观看'
        }
      ],
      cancelFollow,
      toLive,
      ok,
      modal,
      passwordCheckValue,
      handleCancel
    };
  }
};
</script>

<style lang="less" scoped>
@import '../../assets/less/public.less';
.wrapper {
  position: relative;

  .content {
    .live-broadcast-content-box {
      overflow: hidden;
      position: relative;
      .live-broadcast-content {
        width: 1400px;
        margin: 0 auto;
        .consulting-service {
          margin-bottom: 10px;
          h1 {
            background: rgba(255, 255, 255, 0.15);
            border-radius: 14px;
            padding: 4px 0;
            text-align: center;
            .anticon {
              color: #e7853e;
            }
            span {
              cursor: pointer;
              font-size: 12px;
              color: #fff;
              margin-left: 8px;
            }
          }
        }
        .live-player {
          display: flex;
          border-radius: 10px;
          background-color: rgba(0, 0, 0, 0.6);
          .live-player-left {
            width: 78.857%;
            height: 610px;
            border-radius: 10px 0px 0px 10px;
            display: flex;
            justify-content: center;
            position: relative;
            align-items: center;
            img {
              border-radius: 10px 0px 0px 10px;
            }
            span {
              display: block;
              font-size: 24px;
              position: absolute;
              padding: 10px 40px;
              border-radius: 30px;
              cursor: pointer;
              color: #fff;
              background-color: #017fff;
              border-color: #017fff;
              text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
              box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
            }
          }
          .live-player-right {
            width: 21.143%;
            padding: 15px 15px 16px 0;
            display: flex;
            flex-direction: column;
            li:not(:nth-child(5)) {
              margin-bottom: 11px;
            }
            li {
              display: flex;
              justify-content: flex-end;
              flex: 1;
              div {
                position: relative;
                cursor: pointer;
                height: 102px;
                width: 242px;
                border-radius: 5px;
                padding: 3px;
                background: transparent;
                img {
                  border-radius: 5px;
                }
                p {
                  bottom: 10px;
                  position: absolute;
                  text-align: center;
                  color: rgba(255, 255, 255, 0.5);
                  width: 100%;
                  span {
                    .text-overflow(200px);
                    display: inline-block;
                  }
                }
              }
              div::before {
                content: '';
                position: absolute;
                width: calc(100% - 6px);
                background-color: #000;
                opacity: 0.5;
                border-radius: 5px;
                height: calc(100% - 6px);
              }
              .active {
                background-color: #2a94ff;
                p {
                  color: #fff;
                }
                &::before {
                  display: block;
                  content: '';
                  width: 0px;
                  height: 0px;
                  border-top: 10px solid transparent;
                  border-right: 13px solid;
                  border-bottom: 10px solid transparent;
                  position: absolute;
                  margin-left: -13px;
                  top: 50%;
                  opacity: 1;
                  background-color: transparent;
                  transform: translateY(-50%);
                  border-right-color: #2a94ff;
                }
              }
            }
          }
        }
      }
      // &::after {
      //   content: '';
      //   position: absolute;
      //   width: 100%;
      //   background-color: #f6f6f6;
      //   z-index: -1;
      //   height: 28px;
      //   bottom: 0;
      // }
    }

    .list-player-box {
      overflow: hidden;
      position: relative;
      // &::before {
      //   content: '';
      //   position: absolute;
      //   width: 100%;
      //   background-color: #f6f6f6;
      //   height: 100%;
      //   z-index: -1;
      // }
      .list-player {
        width: 1400px;
        margin: 0 auto;
        margin-top: 50px;
        position: relative;
        .list-player-title {
          display: flex;
          font-size: 18px;
          color: #999999;
          margin-bottom: 25px;
          cursor: pointer;
          h3:first-child {
            margin-right: 40px;
          }
        }
        .qrcode {
            position: absolute;
            right: 20px;
            display: flex;
            flex-flow: column;
          div {
            display: flex;
            flex-direction: column;
            p {
              font-size: 14px;
            }
          }
        }
        .active-title {
          color: #333333;
          display: flex;
          flex-direction: column;
          align-items: center;
          &::after {
            width: 24px;
            padding-top: 5px;
            display: block;
            content: '';
            background: #007ff0;
            border-radius: 3px;
          }
        }
        .list-wrapper-box {
          padding-bottom: 212px;
          width: calc(100% - 130px);
          .more {
            width: 280px;
            margin: 40px auto 0;
            font-size: 16px;
            color: #007fff;
            padding: 6.5px 0;
            text-align: center;
            border-radius: 5px;
            border: 1px solid #007fff;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.password-box {
  ::v-deep(.captcha-modal) {
    .ant-modal-content {
      padding: 0;
      .ant-modal-header {
        padding: 58px 0 20px;
        .ant-modal-title {
          font-size: 14px;
          line-height: 22px;
          width: 252px;
          margin: 0 auto;
        }
      }
      .ant-modal-body {
        background-color: #fff;
        padding: 0;
        text-align: center;
        div {
          margin-bottom: 20px;
        }
        input {
          height: 40px;
          border-radius: 5px;
          width: 168px;
        }
      }
      .ant-modal-footer {
        margin: 40px 0 30px;
      }
    }
  }
}
.attention-and-watch {
  grid-template-columns: repeat(4, 300px);
}
</style>
